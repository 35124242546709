import React from "react";
import MainImage from "./MainImage";
import ReactPlayer from "react-player";
import InstagramEmbed from "react-instagram-embed";
import LatexRenderer from "./Latex";

const AuthorReference = ({ node }) => {
  if (node && node.author && node.author.name) {
    return <span>{node.author.name}</span>;
  }
  return <></>;
};

const serializers = {
  types: {
    authorReference: AuthorReference,
    mainImage: ({ node }) => (
      <div className="mt-12 mb-12 m-auto">
        <MainImage mainImage={node} />
      </div>
    ),
    videoEmbed: ({ node }) => (
      <ReactPlayer
        className="mt-12 mb-12 m-auto"
        url={node.url}
        controls
        style={{ width: "100%" }}
      />
    ),
    instagram: ({ node }) => {
      if (!node.url) return null;
      return <InstagramEmbed url={node.url} className="container mx-auto mt-12 mb-12" />;
    },
    math: ({ node, isInline = false }) => <LatexRenderer isInline={isInline} latex={node.latex} />
  }
};

export default serializers;
